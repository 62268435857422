import apiClient from './api'

export default {
    async get (id) {
        return await apiClient.get(`/cms/locations/${id}`)
    },
    async getAll (params = {}) {
        params.paginate = false
        return await apiClient.get('/cms/locations', { params })
    },
    async paginate (params) {
        params.paginate = true
        params.perPage = params.itemsPerPage
        const result = await apiClient.get('/cms/locations', { params })
        result.data.total = Number(result.data.total)
        return result
    },
    async add (params) {
        return await apiClient.post('/cms/locations', apiClient.objectToFormData(params), {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    async edit (id, params) {
        return await apiClient.put(`/cms/locations/${id}`, apiClient.objectToFormData(params), {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    async delete (id) {
        return await apiClient.delete(`/cms/locations/${id}`)
    },
}